import { useI18nStore } from '@/stores/i18nStore'

export const useLandingDownloadStore = defineStore('landingDownloadStore', () => {
  const landingDownload = ref(null)
  const landingDynamic = ref(null)

  const getLandingDownload = computed(() => landingDownload.value)
  const getLandingDynamic = computed(() => landingDynamic.value)

  const fetchLandingDownload = async (partner = 'stockpro') => {
    const config = useRuntimeConfig()
    const i18nStore = useI18nStore()
    const { $api } = useNuxtApp()
    const { request } = useApiRequest()
    try {
      const response = await request({
        req: $api().storyblok.get(`/landingdownload/${partner}`, {
          version: config.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft',
          uniqueStory: true,
          language: i18nStore.langCode(true),
        }),
      })

      landingDownload.value = response.data.story.content
    } catch (e) {
      console.error(e)
    }
  }
  const setLandingDynamic = payload => {
    landingDynamic.value = payload
  }

  return {
    landingDownload,
    landingDynamic,
    getLandingDownload,
    getLandingDynamic,
    fetchLandingDownload,
    setLandingDynamic,
  }
})
